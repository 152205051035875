import Grid from '@mui/material/Unstable_Grid2';
import Auth from "../components/Auth";
import Paper from "@mui/material/Paper";

import { useNavigate, useLocation } from "react-router-dom";
import useSWR from "swr";

import { useEffect } from "react";
import Loading from '../components/Loading';

function Home({ link }) {

    const navigate = useNavigate();
    const location = useLocation();

    const fetcher = async () => {

        const verifySessionReq = await fetch(`${link}/verifySession`, { method: "GET", credentials: "include" });

        const verifySession = await verifySessionReq.json();

        return { verifySession }

    }

    const { data, isLoading } = useSWR("/api/verifySession", fetcher);

    useEffect(() => {

        if (data?.verifySession?.authenticated === true ) {

            navigate("/dashboard");
    
        }

    }, [data, isLoading]);

    if (isLoading) return <Loading wholeScreen={true} />

    return (
        <Grid container justifyContent="center" alignItems="center" sx={{margin: 0, height: "100vh", width: "100vw", backgroundColor: "#90EE90"}} spacing={1}>
            <Paper sx={{display: "flex", justifyContent: "center", padding: "2rem"}} elevation={3}>
                <Grid container justifyContent="center" alignItems="center" spacing={1}>
                    <Grid xs={12} justifyContent="center" alignItems="center">
                        <Grid>
                            {location?.state?.isLoggingOff ? <p style={{textAlign: "center"}}>You have logged off successfully. Please close your window. </p> : <Auth title="Guma Tinemtom" link={link}/> }
                        </Grid>
                    </Grid>
                    <Grid xs={12} justifyContent="center" alignItems="center">
                            <p style={{textAlign: "center"}}>Version: v1.0.4 (1/19/2025)</p>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>

    );

};

export default Home;