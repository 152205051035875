// Standard imports
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { useParams } from 'react-router-dom';
import { useState, useEffect, useCallback } from "react";
import { Model, FunctionFactory } from "survey-core";
import { Survey } from "survey-react-ui";
import useSWR from "swr";   

// Adult Surveys
import { chl } from '../../questions/chl';
import { cvc } from '../../questions/cvc';
import { his } from '../../questions/his';
import { hyp } from '../../questions/hyp';
import { lsf } from '../../questions/lsf';
import { ast } from '../../questions/ast';
import { can } from '../../questions/can';
import { dib } from '../../questions/dib';
import { con } from '../../questions/_con';
import { epi } from '../../questions/epi';
import { bmi } from '../../questions/bmi';
import { vis } from '../../questions/vis';
import { hea } from '../../questions/hea';
import { mob } from '../../questions/mob';
import { cog } from '../../questions/cog';
import { upp } from '../../questions/upp';
import { ins } from '../../questions/ins';
import { pay } from '../../questions/pay';
import { cvl } from '../../questions/cvl';
import { dnc } from '../../questions/dnc';
import { utz } from '../../questions/utz';
import { abh } from '../../questions/abh';
import { tlh } from '../../questions/tlh';
import { pmd, pmd_c } from '../../questions/pmd';
import { ims, ims_c } from '../../questions/ims';
import { ptc } from '../../questions/ptc';
import { anx, anx_c } from '../../questions/anx';
import { dep } from '../../questions/dep';
import { mhc } from '../../questions/mhc';
import { phq } from '../../questions/phq';
import { gad } from '../../questions/gad';
import { cig } from '../../questions/cig';
import { otb } from '../../questions/otb';
import { aqs } from '../../questions/aqs';
import { phy, phy_c } from '../../questions/phy';
import { wlk } from '../../questions/wlk';
import { cpa } from '../../questions/cpa';
import { slp, slp_c } from '../../questions/slp';
import { dnu } from '../../questions/dnu';
import { cih } from '../../questions/cih';
import { gni } from '../../questions/gni';
import { mar } from '../../questions/mar';
import { vet } from '../../questions/vet';
import { nat } from '../../questions/nat';
import { sch } from '../../questions/sch';
import { emp } from '../../questions/emp';
import { inc } from '../../questions/inc'; // needs universe logic
import { foo } from '../../questions/foo'; // needs universe logic
import { fds } from '../../questions/fds';
import { hou } from '../../questions/hou';
import { tbh } from '../../questions/tbh';
import { tel } from '../../questions/tel';

// import { gout } from '../../questions/gout';
import { misc } from '../../questions/misc';

// Child Surveys

import { dld } from '../../questions/dld';
// import { imm } from '../../questions/imm';
import { cvv } from '../../questions/cvv';
import { hpv } from '../../questions/hpv';
import { sdq } from '../../questions/sdq';
import { sle } from '../../questions/sle';
import { bly } from '../../questions/bly';
import { tbi } from '../../questions/tbi';
import { nhc } from '../../questions/nhc';
import { scr } from '../../questions/scr';
import { fem } from '../../questions/fem';

import Loading from '../../components/Loading';
import Error from '../Error';

function AccountSurvey({ link }) {

    const { surveyModule, id } = useParams();
    const [ survey, setSurvey ] = useState({});


    const surveyStorageKey = `${id}-${surveyModule}`;
    const surveyLength = survey.length > 0 ? survey.length : "None";

    const fetcher = async function() {

        const patientReq = await fetch(`${link}/patient/${id}`, { credentials: "include" });

        if (surveyModule) {

            const patientModuleReq = await fetch(`${link}/patient/${id}/modules/${surveyModule}`, { credentials: 'include' })
            const patient = await patientReq.json();
            const patientModule = await patientModuleReq.json();
    
            return { patient, patientModule }

        }

        const patient = await patientReq.json();

        return { patient };

    }

    const { data, isLoading } = useSWR(`/api/${id}/${surveyStorageKey}`, fetcher);

    const surveyModel = new Model({
        elements: survey,
        calculatedValues: [
            {
                "name": "povertyLevel",
                "expression": "povertyCalc({INCTOTALFAMCOUNT_A})"
            },
            {
                "name": "250ofPovertyLevel",
                "expression": "{povertyLevel} * 2.5"
            },
            {
                "name": "138ofPovertyLevel",
                "expression": "{povertyLevel} * 1.38"
            },
            {
                "name": "200ofPovertyLevel",
                "expression": "{povertyLevel} * 2.0"
            },
            {
                "name": "surveyeeSex",
                "expression": `${data?.patient?.patient?.gender || "none"}`
            }
        ]
        });

    function povertyCalc(params) {

        const familySize = params[0];

        if (familySize > 8) {

            return 52750 + (familySize - 8) * 5380;

        }

        switch (familySize) {

            case 1:
                return 15060;
            case 2:
                return 20440;
            case 3:
                return 25820;
            case 4:
                return 31200;
            case 5:
                return 36580;
            case 6:
                return 41960;
            case 7:
                return 47340;
            case 8:
                return 52720;
            default:
                return -1;

        }

    };

    FunctionFactory.Instance.register("povertyCalc", povertyCalc);

    useEffect(() => {

        switch (surveyModule) {
            case "chl":
                setSurvey(chl);
                break;
            case "cvc":
                setSurvey(cvc);
                break;
            case "his":
                setSurvey(his);
                break;
            case "hyp":
                setSurvey(hyp);
                break;
            case "lsf":
                setSurvey(lsf);
                break;
            case "ast":
                setSurvey(ast);
                break;
            case "can":
                setSurvey(can);
                break;
            case "dib":
                setSurvey(dib);
                break;
            case "con":
                setSurvey(con);
                break;
            case "epi":
                setSurvey(epi);
                break;
            case "bmi":
                setSurvey(bmi);
                break;
            case "vis":
                setSurvey(vis);
                break;
            case "hea":
                setSurvey(hea);
                break;
            case "mob":
                setSurvey(mob);
                break;
            case "cog":
                setSurvey(cog);
                break;
            case "upp":
                setSurvey(upp);
                break;
            case "ins":
                setSurvey(ins);
                break;
            case "pay":
                setSurvey(pay);
                break;
            case "cvl":
                setSurvey(cvl);
                break;
            case "dnc":
                setSurvey(dnc);
                break;
            case "utz":
                setSurvey(utz);
                break;
            case "abh":
                setSurvey(abh);
                break;
            case "tlh":
                setSurvey(tlh);
                break;
            case "pmd":
                data?.patient?.patient?.age < 18 ? setSurvey(pmd_c) : setSurvey(pmd);
                break;
            case "ims":
                data?.patient?.patient?.age < 18 ? setSurvey(ims_c) : setSurvey(ims);
                break;
            case "ptc":
                setSurvey(ptc);
                break;
            case "anx":
                data?.patient?.patient?.age < 18 ? setSurvey(anx_c) : setSurvey(anx);
                break;
            case "dep":
                setSurvey(dep);
                break;
            case "mhc":
                setSurvey(mhc);
                break;
            case "phq":
                setSurvey(phq);
                break;
            case "gad":
                setSurvey(gad);
                break;
            case "cig":
                setSurvey(cig);
                break;
            case "otb":
                setSurvey(otb);
                break;
            case "aqs":
                setSurvey(aqs);
                break;
            case "phy":
                data?.patient?.patient?.age < 18 ? setSurvey(phy_c) : setSurvey(phy);
                break;
            case "wlk":
                setSurvey(wlk);
                break;
            case "cpa":
                setSurvey(cpa);
                break;
            case "slp":
                data?.patient?.patient?.age < 18 ? setSurvey(slp_c) : setSurvey(slp);
                break;
            case "dnu":
                setSurvey(dnu);
                break;
            case "cih":
                setSurvey(cih);
                break;
            case "gni":
                setSurvey(gni);
                break;
            case "mar":
                setSurvey(mar);
                break;
            case "vet":
                setSurvey(vet);
                break;
            case "nat":
                setSurvey(nat);
                break;
            case "sch":
                setSurvey(sch);
                break;
            case "emp":
                setSurvey(emp);
                break;
            case "inc":
                // Add universe logic for "inc" survey
                setSurvey(inc);
                break;
            case "foo":
                // Add universe logic for "foo" survey
                setSurvey(foo);
                break;
            case "fds":
                setSurvey(fds);
                break;
            case "hou":
                setSurvey(hou);
                break;
            case "tbh":
                setSurvey(tbh);
                break;
            case "tel":
                setSurvey(tel);
                break;
            case "dld":
                setSurvey(dld);
                break;
            case "cvv":
                setSurvey(cvv);
                break;
            case "hpv":
                setSurvey(hpv);
                break;
            case "sdq":
                setSurvey(sdq);
                break;
            case "sle":
                setSurvey(sle);
                break;
            case "bly":
                setSurvey(bly);
                break;
            case "tbi":
                setSurvey(tbi);
                break;
            case "nhc":
                setSurvey(nhc);
                break;
            case "scr":
                setSurvey(scr);
                break;
            case "fem":
                setSurvey(fem);
                break;
            case "misc":
                setSurvey(misc);
                break;
            default:
                setSurvey({});
                break;
        };

        if (surveyModule === "fem") {

            async function fetchHouseholdAndCreateFEMQuestions() {

                try {

                    const householdReq = await fetch(`${link}/patient/${id}/household?isAdult=${data?.patient?.patient?.age < 18 ? "false" : "true"}`, { credentials: "include" });
                    const household = await householdReq.json();
                    const otherHouseholdMembers = household?.household[0]?.otherHouseholdMembers;

                    if (!otherHouseholdMembers) return;
    
                    for (let i = 0; i < otherHouseholdMembers.length; i++) {
    
                        const req = await fetch(`${link}/patient/${otherHouseholdMembers[i]}`, { credentials: "include" });
                        const res = await req.json();
                        console.log(res);
                        const memberId = res.patient._id;
                        const member = res.patient.firstName + " " + res.patient.lastName;
                        const newPage = surveyModel.addNewPage(`otherHouseholdMembersFEM-${i}`);
    
                        const jsonFEMWORK = {
                            type: "radiogroup",
                            name: `FEMWORK_C-${i}`,
                            title: `Does ${member} (${memberId}) work for pay at a job or business?`,
                            isRequired: false,
                            colCount: 1,
                            choices: [
                                {
                                    value: 1,
                                    text: "Yes"
                                },
                                {
                                    value: 2,
                                    text: "No"
                                },
                                {
                                    value: 7,
                                    text: "Refused"
                                },
                                {
                                    value: 9,
                                    text: "Don't Know"
                                }
                            ],
                            hasOther: false,
                            otherText: "Other (Please specify)",
                            requiredErrorText: "Please select an option."
                            // Skip logic can be implemented here if needed.
                        };
    
                        const jsonFEMWKFT =   {
                            type: "radiogroup",
                            name: `FEMWKFT_C-${i}`,
                            title: `Does ${member} (${memberId}) usually work 35 hours or more per week in total at at your job or jobs?`,
                            isRequired: false,
                            colCount: 1,
                            choices: [
                                {
                                    value: 1,
                                    text: "Yes"
                                },
                                {
                                    value: 2,
                                    text: "No"
                                },
                                {
                                    value: 7,
                                    text: "Refused"
                                },
                                {
                                    value: 9,
                                    text: "Don't Know"
                                }
                            ],
                            hasOther: false,
                            otherText: "Other (Please specify)",
                            requiredErrorText: "Please select an option." // Assuming 'ADLT18_A' represents adults over 18 years in Sample Child's family and 'FEMWORK_C' represents whether the respondent works for pay at a job or business
                            // Skip logic can be implemented here if needed.
                        };
    
                        const q1 = newPage.addNewQuestion("radiogroup", `FEMWORK_C-${i}`);
                        q1.fromJSON(jsonFEMWORK);
    
                        const q2 = newPage.addNewQuestion("radiogroup", `FEMWKFT_C-${i}`);
                        q2.fromJSON(jsonFEMWKFT);
    
                    }


                } catch (err) {

                    console.error(err);

                }
            
            }

            fetchHouseholdAndCreateFEMQuestions();

        }

        if (surveyModule === "inc") {

            async function fetchHouseholdAndCreateINCQuestions() {

                try {


                    const householdReq = await fetch(`${link}/patient/${id}/household?isAdult=${data?.patient?.patient?.age < 18 ? "false" : "true"}`, { credentials: "include" });
                    const household = await householdReq.json();
                    const otherHouseholdMembers = household.household[0].otherHouseholdMembers;
    
                    if (!otherHouseholdMembers) return;
    
                    for (let i = 0; i < otherHouseholdMembers.length; i++) {
    
                        const req = await fetch(`${link}/patient/${otherHouseholdMembers[i]}`, { credentials: "include" });
                        const res = await req.json();
                        const memberId = res.patient._id;
                        const member = res.patient.firstName + " " + res.patient.lastName;
                        const newPage = surveyModel.addNewPage(`otherHouseholdMembersINC-${i}`);
    
                        const jsonINCWRKO = {
                            type: "radiogroup",
                            name: `INCWRKO_A-${i}`,
                            title: `In the last year, did ${member} (${memberId}) receive income from wages, salaries, commissions, bonuses, tips, or self-employment?`,
                            isRequired: false,
                            colCount: 1,
                            choices: [
                                {
                                    value: 1,
                                    text: "Yes"
                                },
                                {
                                    value: 2,
                                    text: "No"
                                },
                                {
                                    value: 7,
                                    text: "Refused"
                                },
                                {
                                    value: 9,
                                    text: "Don't Know"
                                }
                            ],
                            hasOther: false,
                            otherText: "Other (Please specify)",
                            requiredErrorText: "Please select an option."
                            // Skip logic can be implemented here if needed.
                        };
    
                        const jsonINCINTER = {
                            type: "radiogroup",
                            name: `INCINTER_A-${i}`,
                            title: `In the last year, did ${member} (${memberId}) receive income from interest-bearing accounts or investments, dividends from stocks or mutual funds, net rental income, royalty income, or income from estates and trusts?`,
                            isRequired: false,
                            colCount: 1,
                            choices: [
                                {
                                    value: 1,
                                    text: "Yes"
                                },
                                {
                                    value: 2,
                                    text: "No"
                                },
                                {
                                    value: 7,
                                    text: "Refused"
                                },
                                {
                                    value: 9,
                                    text: "Don't Know"
                                }
                            ],
                            hasOther: false,
                            otherText: "Other (Please specify)",
                            requiredErrorText: "Please select an option."
                            // Skip logic can be implemented here if needed.
                        }
    
                        const jsonINCSSRR =  {
                            type: "radiogroup",
                            name: `INCSSRR_A-${i}`,
                            title: `In the last year, did ${member} (${memberId}) receive income from Social Security or Railroad Retirement?`,
                            isRequired: false,
                            colCount: 1,
                            choices: [
                                {
                                    value: 1,
                                    text: "Yes"
                                },
                                {
                                    value: 2,
                                    text: "No"
                                },
                                {
                                    value: 7,
                                    text: "Refused"
                                },
                                {
                                    value: 9,
                                    text: "Don't Know"
                                }
                            ],
                            hasOther: false,
                            otherText: "Other (Please specify)",
                            requiredErrorText: "Please select an option."
                            // Skip logic can be implemented here if needed.
                        };
    
                        const jsonINCSSISSDI = {
                            type: "radiogroup",
                            name: `INCSSISSDI_A-${i}`,
                            title: `In the last year, did ${member} (${memberId}) receive Supplemental Security Income (SSI) or Social Security Disability Income (SSDI), which are different from Social Security?`,
                            isRequired: false,
                            colCount: 1,
                            choices: [
                                {
                                    value: 1,
                                    text: "Yes"
                                },
                                {
                                    value: 2,
                                    text: "No"
                                },
                                {
                                    value: 7,
                                    text: "Refused"
                                },
                                {
                                    value: 9,
                                    text: "Don't Know"
                                }
                            ],
                            hasOther: false,
                            otherText: "Other (Please specify)",
                            requiredErrorText: "Please select an option."
                            // Skip logic can be implemented here if needed.
                        };
    
                        const jsonSSISSDIBTH =  {
                            type: "radiogroup",
                            name: `SSISSDIBTH_A-${i}`,
                            title: "Was this Supplemental Security Income (SSI), Social Security Disability Income (SSDI), or both?",
                            isRequired: false,
                            colCount: 1,
                            choices: [
                                {
                                    value: 1,
                                    text: "SSI"
                                },
                                {
                                    value: 2,
                                    text: "SSDI"
                                },
                                {
                                    value: 3,
                                    text: "Both SSI and SSDI"
                                },
                                {
                                    value: 7,
                                    text: "Refused"
                                },
                                {
                                    value: 9,
                                    text: "Don't Know"
                                }
                            ],
                            hasOther: false,
                            otherText: "Other (Please specify)",
                            requiredErrorText: "Please select an option."
                            // Skip logic can be implemented here if needed.
                        }
    
                        const jsonSSISSDIDSB = {
                            type: "radiogroup",
                            name: `SSISSDIDSB_A-${i}`,
                            title: "Was this received as a disability benefit?",
                            isRequired: false,
                            colCount: 1,
                            choices: [
                                {
                                    value: 1,
                                    text: "Yes"
                                },
                                {
                                    value: 2,
                                    text: "No"
                                },
                                {
                                    value: 7,
                                    text: "Refused"
                                },
                                {
                                    value: 9,
                                    text: "Don't Know"
                                }
                            ],
                            hasOther: false,
                            otherText: "Other (Please specify)",
                            requiredErrorText: "Please select an option."
                            // Skip logic can be implemented here if needed.
                        };
    
                        const jsonINCWELF = {
                            type: "radiogroup",
                            name: `INCWELF_A-${i}`,
                            title: `In the last year, did ${member} (${memberId}) receive any public assistance or welfare payments from the state or local welfare office?`,
                            isRequired: false,
                            colCount: 1,
                            choices: [
                                {
                                    value: 1,
                                    text: "Yes"
                                },
                                {
                                    value: 2,
                                    text: "No"
                                },
                                {
                                    value: 7,
                                    text: "Refused"
                                },
                                {
                                    value: 9,
                                    text: "Don't Know"
                                }
                            ],
                            hasOther: false,
                            otherText: "Other (Please specify)",
                            requiredErrorText: "Please select an option."
                            // Skip logic can be implemented here if needed.
                        };
    
                        const jsonINCRETIRE = {
                            type: "radiogroup",
                            name: `INCRETIRE_A-${i}`,
                            title: `In the last year, did ${member} (${memberId}) receive income from retirement, survivor, or disability pensions?`,
                            isRequired: false,
                            colCount: 1,
                            choices: [
                                {
                                    value: 1,
                                    text: "Yes"
                                },
                                {
                                    value: 2,
                                    text: "No"
                                },
                                {
                                    value: 7,
                                    text: "Refused"
                                },
                                {
                                    value: 9,
                                    text: "Don't Know"
                                }
                            ],
                            hasOther: false,
                            otherText: "Other (Please specify)",
                            requiredErrorText: "Please select an option."
                            // Skip logic can be implemented here if needed.
                        };
    
                        const jsonINCOTHR = {
                            type: "radiogroup",
                            name: `INCOTHR_A-${i}`,
                            title: `In the last year, did ${member} (${memberId}) receive any other sources of taxable and/or non-taxable income such as VA payments from the Veterans Benefits Administration, unemployment compensation, child support, or alimony?`,
                            isRequired: false,
                            colCount: 1,
                            choices: [
                                {
                                    value: 1,
                                    text: "Yes"
                                },
                                {
                                    value: 2,
                                    text: "No"
                                },
                                {
                                    value: 7,
                                    text: "Refused"
                                },
                                {
                                    value: 9,
                                    text: "Don't Know"
                                }
                            ],
                            hasOther: false,
                            otherText: "Other (Please specify)",
                            requiredErrorText: "Please select an option."
                            // Skip logic can be implemented here if needed.
                        };
    
                        const q1 = newPage.addNewQuestion("radiogroup", `INCWRKO_A-${i}`)
                        q1.fromJSON(jsonINCWRKO);
    
                        const q2 = newPage.addNewQuestion("radiogroup", `INCINTER_A-${i}`)
                        q2.fromJSON(jsonINCINTER);
    
                        const q3 = newPage.addNewQuestion("radiogroup", `INCSSRR_A-${i}`);
                        q3.fromJSON(jsonINCSSRR);
    
                        const q4 = newPage.addNewQuestion("radiogroup", `INCSSISSDI_A-${i}`);
                        q4.fromJSON(jsonINCSSISSDI);
    
                        const q5 = newPage.addNewQuestion("radiogroup", `SSISSDIBTH_A-${i}`);
                        q5.fromJSON(jsonSSISSDIBTH);
    
                        const q6 = newPage.addNewQuestion("radiogroup", `SSISSDIDSB_A-${i}`);
                        q6.fromJSON(jsonSSISSDIDSB);
    
                        const q7 = newPage.addNewQuestion("radiogroup", `INCWELF_A-${i}`);
                        q7.fromJSON(jsonINCWELF);
    
                        const q8 = newPage.addNewQuestion("radiogroup", `INCRETIRE_A-${i}`);
                        q8.fromJSON(jsonINCRETIRE);
    
                        const q9 = newPage.addNewQuestion("radiogroup", `INCOTHR_A-${i}`);
                        q9.fromJSON(jsonINCOTHR);
    
                    }

                } catch (err) {

                    console.error(err);

                }


            }

            fetchHouseholdAndCreateINCQuestions();

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [surveyModule, isLoading]);

    const saveResults = function() {

        const data = surveyModel.data;
        window.localStorage.setItem(surveyStorageKey, JSON.stringify(data));
        console.log("Saved results of survey in local storage.");

    }

    const sendResultstoBackend = useCallback(async (sender) => {

        console.log(JSON.stringify(sender.data));

        const submissionReq = await fetch(`${link}/patient/${id}/modules/${surveyModule}`, {
            headers: {
                "Content-Type": "application/json"
            },
            method: "PUT",
            body: JSON.stringify(sender.data),
            credentials: "include"
        });

        const submission = await submissionReq.json();
        window.localStorage.setItem(surveyStorageKey, "");

        if (submission) {

            return true;

        }

        return false;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    surveyModel.onValueChanged.add(saveResults);
    surveyModel.onComplete.add(sendResultstoBackend);

    if (window.localStorage.getItem(surveyStorageKey)) {

        surveyModel.data = JSON.parse(window.localStorage.getItem(surveyStorageKey));

    } else if (data?.patientModule?.patient?.responses) {

        for (let i = 0; i < data.patientModule.patient.responses.length; i++) {

            if (data.patientModule.patient.responses[i].module === surveyModule) {

                
                surveyModel.data = data.patientModule.patient.responses[i].moduleResponses;

            }

        }

    }

    return (
        <>
        <Grid container direction="column" minHeight="5vh">
            <Typography variant="h4" component="h2" sx={{ fontWeight: "bolder" }}>
                {data?.patient?.patient?.firstName.toUpperCase()} {data?.patient?.patient?.lastName ? `${data?.patient?.patient?.lastName.toUpperCase()}'s ${surveyModule.toUpperCase()} MODULE` : `'s ${surveyModule.toUpperCase()} MODULE`}
            </Typography>
            <Typography variant="h5" component="h3" sx={{ fontWeight: "bolder" }}>
                SURVEYEE ID: {data?.patient?.patient?._id}
            </Typography>
            <Typography variant="h5" component="h3" sx={{ fontWeight: "bolder" }}>
                SURVEY LENGTH: {surveyLength}
            </Typography>           
        </Grid>
        <Grid maxHeight="100vh">
            { isLoading ? <Loading /> : <Survey model={surveyModel} /> }
        </Grid>
        </>
    );


};

export default AccountSurvey;